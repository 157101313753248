import axios from 'axios';

export default {
  async getAll() {
    const response = await axios.get('/api/project/', { params: { page_size: 999 }});
    return response.data.results;
  },
  async getPage(page) {
    const response = await axios.get(`/api/project/?page=${page}`);
    return response.data.results;
  },
  async getSearchPage(page, name) {
    const response = await axios.get(`/api/project/?page=${page}&search=${name}`);
    return response.data.results;
  },
  async getProject(id) {
    const response = await axios.get(`/api/project/${id}/`);
    return response.data;
  },
  async updateProject(id, updatedParameters) {
    const response = await axios.patch(`/api/project/${id}/`, updatedParameters);
    return response.data;
  },
  async createProject(project) {
    const response = await axios.post('/api/project/', project);
    return response.data;
  },
  async getProjectsByName(name) {
    const response = await axios.get(`/api/project/?search=${name}`);
    return response.data.results;
  },
  async getSimulations(projectId, studyId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/simulation/`);
    return response.data;
  },
  async getSimulationsForConfiguration(projectId, studyId, configurationId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/simulation/`);
    return response.data;
  },
  async getSimulation(projectId, studyId, simulationId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/simulation/${simulationId}/`);
    return response.data;
  },
  async getCameraPositions(projectId, studyId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/camera-position/`);
    return response.data;
  },
  async addCameraPosition(projectId, studyId, cameraPosition) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/camera-position/`, cameraPosition);
    return response.data;
  },
  async addInsight(projectId, studyId, insightDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/insight/`, insightDetails);
    return response.data;
  },
  async updateInsight(projectId, studyId, insight) {
    const response = await axios.put(`/api/project/${projectId}/study/${studyId}/insight/${insight.id}/`, insight);
    return response.data;
  },
  async deleteInsight(projectId, studyId, insightId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/`);
    return response;
  },
  async addSceneView(projectId, studyId, insightId, insightDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/scene-view/`, insightDetails);
    return response.data;
  },
  async deleteSceneView(projectId, studyId, insightId, sceneViewId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/scene-view/${sceneViewId}/`);
    return response.data;
  },
  async addInsightPin(projectId, studyId, insightId, insightPinDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin/`, insightPinDetails);
    return response.data;
  },
  async deleteInsightPin(projectId, studyId, insightId, annotationId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin/${annotationId}/`);
    return response;
  },
  async updateInsightPin(projectId, studyId, insightId, insightPinId, updatedInsightPinDetails) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin/${insightPinId}/`, updatedInsightPinDetails);
    return response.data;
  },
  async addInsightPinType(projectId, studyId, insightId, insightPinTypeDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin-type/`, insightPinTypeDetails);
    return response.data;
  },
  async deleteInsightPinType(projectId, studyId, insightId, annotationTypeId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin-type/${annotationTypeId}/`);
    return response;
  },
  async updateInsightPinType(projectId, studyId, insightId, insightPinId, updatedInsightPinTypeDetails) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin-type/${insightPinId}/`, updatedInsightPinTypeDetails);
    return response.data;
  },
  async getInsightPinType(projectId, studyId, insightId, insightPinId, insightPinTypeQueryDetails) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/insight-pin-type/${insightPinId}/`, insightPinTypeQueryDetails);
    return response.data;
  },
  async updateCameraPosition(projectId, studyId, id, updatedCameraPosition) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/camera-position/${id}/`, updatedCameraPosition);
    return response.data;
  },
  async deleteCameraPosition(projectId, studyId, cameraPositionId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/camera-position/${cameraPositionId}/`);
    return response.data;
  },
  async fetchMetData(projectId, metData) {
    const response = await axios.post(`api/project/${projectId}/fetch-met-data/`, metData);
    return response.data;
  },
  async getProjectSurrounds(projectId) {
    const response = await axios.get(`api/project/${projectId}/surrounds/`);
    return response.data;
  },
  async generateSurroundsOSM(projectId) {
    const response = await axios.post(`api/project/${projectId}/surrounds/generate-osm/`);
    return response.data;
  },
  async getBlobUrl(projectId) {
    const response = await axios.get(`api/project/${projectId}/asset-upload-url/`);
    return response.data;
  },
  async getBlobUrlForProjectAssets(projectId) {
    const response = await axios.get(`api/project/${projectId}/project-asset-upload-url/`);
    return response.data;
  },
  async createScenario(projectId, scenario) {
    const response = await axios.post(`/api/project/${projectId}/configuration/`, scenario);
    return response.data;
  },
  async getScenario(projectId, studyId, configurationId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/`);
    return response.data;
  },
  async replaceScenario(projectId, studyId, configurationId, scenario) {
    const response = await axios.put(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/`, scenario);
    return response.data;
  },
  async createGeometryValidationSteps(projectId, studyId, configurationId, geometryValidationSteps) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/geometry-validation/`, geometryValidationSteps);
    return response.data;
  },
  async replaceGeometryValidationSteps(projectId, studyId, configurationId, geometryValidationStepsId, geometryValidationSteps) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/geometry-validation/${geometryValidationStepsId}/`, geometryValidationSteps);
    return response.data;
  },
  async getGeometryValidationSteps(projectId, studyId, configurationId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/geometry-validation/`);
    return response.data;
  },
  async deleteScenario(projectId, studyId, configurationId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/`);
    return response.data;
  },
  async duplicateScenario(projectId, configurationId, newScenarioName) {
    const response = await axios.post(`/api/project/${projectId}/configuration/${configurationId}/duplicate-scenario/`, { new_name: newScenarioName
    });
    return response.data;
  },
  async hideScenario(projectId, studyId, configurationId) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/hide-scenario/`);
    return response.data;
  },
  async appendImportResults(gltfMetaData ) {
    const response = await axios.put('/api/ingestassets/appendresults', gltfMetaData);    
    return response.data;
  },
  async importResults(gltfMetaData) {
    const response = await axios.post('/api/ingestassets/simulationresults', gltfMetaData);  
    // if(response.data?.simulationId){
    //   gltfMetaData['simulationId'] = response.data.simulationId;
    //   await this.appendImportResults(gltfMetaData);
    // }
    return response.data;
  },
  async updateScenario(projectId, studyId, configurationId, scenario) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/user-approval/`, scenario);
    return response.data;
  },
  async getAdminProjectList(userId) {                                                                                                                                                                                      
    const response = await axios.get(`api/user/project-admin-projects/${userId.user_id}`);
    return response.data;
  },
  async removeUserAsProjectAdmin(userId) {                                                                                                                                                                                      
    const response = await axios.delete(`api/user/project-admin-projects/${userId.user_id}`);
    return response.data;
  },
  async resubmitMlScenario(projectId, studyId, configurationId, simulationId) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/simulation/${simulationId}/resubmit-ml/`);
    return response.data;
  },
  async getSimulationAsset(projectId, studyId, configurationId, simulationId, simulationAssetId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/simulation/${simulationId}/simulation-asset/${simulationAssetId}/`);
    return response.data;
  },
  async getUserSelectedLayers(projectId, studyId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/user-selected-layer/`);
    return response.data;
  },
  async deleteUserSelectedLayers(projectId, studyId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/user-selected-layer/`);
    return response.data;
  },
  async replaceUserSelectedLayers(projectId, studyId, data) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/user-selected-layer/`, data);
    return response.data;
  },
  async deleteUserSelectedLayer(projectId, studyId, userLayerId) {
    const response = await axios.delete(`/api/project/${projectId}/study/${studyId}/user-selected-layer/${userLayerId}/`);
    return response.data;
  },
  async createUserInvite(projectId, values) {
    const response = await axios.post(`/api/project/${projectId}/user-invite/`, values);
    return response.data;
  },
  async createProjectShare(projectId, values) {
    const response = await axios.post(`/api/project/${projectId}/project-share/`, values);
    return response.data;
  },
  async getProjectShares(projectId) {
    const response = await axios.get(`/api/project/${projectId}/project-share/`);
    return response.data;
  },
  async deleteProjectShare(projectId, projectShareId) {
    const response = await axios.delete(`/api/project/${projectId}/project-share/${projectShareId}/`);
    return response.data;
  },
  async updateProjectShare(projectId, projectShareId, newValues) {
    const response = await axios.patch(`/api/project/${projectId}/project-share/${projectShareId}/`, newValues);
    return response.data;
  },
  async getProjectAdministrators(projectId) {
    const response = await axios.get(`/api/project/${projectId}/project-administrator/`);
    return response.data;
  },
  async createProjectAdministrator(projectId, newAdmin) {
    const response = await axios.post(`/api/project/${projectId}/project-administrator/`, newAdmin);
    return response.data;
  },
  async deleteProjectAdministrator(projectId, projectAdminId) {
    const response = await axios.delete(`/api/project/${projectId}/project-administrator/${projectAdminId}/`);
    return response.data;
  },
  async remeshStl(projectId, studyId, configurationId, simulationId, simulationAssetId) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/simulation/${simulationId}/simulation-asset/${simulationAssetId}/remesh-stl/`);
    return response.data;
  },
  async getGlobalTemplates() {
    const response = await axios.get('/api/global-template');
    return response.data;
  },
  async getJobTypes() {
    const response = await axios.get('/api/job-type/');
    return response.data;
  },
  async getCriteria() {
    const response = await axios.get('/api/criteria/');
    return response.data;
  },
  async getColorLegendByName(name, version) {
    const response = await axios.get(`/api/color-legend/?name=${name}&version=${version}`);
    return response.data;
  },
  async getDataCriteriaGraphDefinitions() {
    const response = await axios.get('/api/data-criteria-graph-definition');
    return response.data;
  },
  async getLatestMLAPISubmissionStatus(projectId, studyId, configurationId, simulationId) {
    const response = await axios.get(`/api/project/${projectId}/study/${studyId}/configuration/${configurationId}/simulation/${simulationId}/get-latest-status/`);
    return response.data;
  },
  async getBadStations() {
    const response = await axios.get('/api/bad-stations');
    return response.data;
  },
  async getInsightPinTypeTemplates() {
    const response = await axios.get('/api/insight-pin-type-templates');
    return response.data;
  },
  async saveDrawing(drawing) {
    const response = await axios.post('/api/drawings', drawing);
    return response.data;
  },
  async updateDrawing(drawing) {
    const response = await axios.put('/api/drawings', drawing);
    return response.data;
  },
  async deleteDrawing(drawing_id) {
    const response = await axios.delete(`/api/drawings/${drawing_id}`);
    return response.data;
  },
  async getInsightCategories() {
    const response = await axios.get('/api/insight-categories');
    return response.data;
  },
  async addImageView(projectId, studyId, insightId, insightDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/image-view/`, insightDetails);
    return response.data;
  },
  async updateImageView(projectId, studyId, insightId, imageViewId, insightDetails) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/image-view/${imageViewId}/`, insightDetails);
    return response.data;
  },
  async addStudyImage(projectId, studyId, studyImageDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/study-image/`, studyImageDetails);
    return response.data;
  },
  async addReportView(projectId, studyId, insightId, insightDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/report-view/`, insightDetails);
    return response.data;
  },
  async updateReportView(projectId, studyId, insightId, reportViewId, insightDetails) {
    const response = await axios.patch(`/api/project/${projectId}/study/${studyId}/insight/${insightId}/report-view/${reportViewId}/`, insightDetails);
    return response.data;
  },
  async addReport(projectId, studyId, reportDetails) {
    const response = await axios.post(`/api/project/${projectId}/study/${studyId}/report/`, reportDetails);
    return response.data;
  },
  async exportPDF(pdfDetails) {
    const response = await axios.post('/api/export-pdf/', pdfDetails, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob'
    });
    return response.data;
  },
  async getSimulationSettingsPDF(simulationId) {
    const response = await axios.get(`/api/simulation/${simulationId}/parameter-input-qa-report`, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob'
    });
    return response.data;
  }
};

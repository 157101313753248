import _ from 'lodash';

import projectApiService from '@/network/project';
import { Asset } from '@/models/asset';
import { Input } from '@/models/input';
import { grouper } from '@/models/layer-set-grouping';
import { LayerSet } from '@/models/layer-set';
import AssetSorting from '@/utilities/asset/asset-sorting';
import PropertySetter  from '@/utilities/asset/asset-property-modifier';
import { UserSelectedLayers } from '@/models/user-selected-layers';

function initialState() {
  return {
    selectedSimulationId: null,
    selectedSecondaryViewerSimulationId: null,
    selectedInsightConfigurationId: null,
    simulations: null,
    simulationsFinishedLoading: false,
    firstSimulationFinishedLoading: 0,
    colorLegend: {},
    currentColorLegendKey: null,
    error: null,
    globalFilter: {},
    secondaryViewerGlobalFilter: {},
    selectedInsightGlobalFilter: {},
    selectedLayers: [],
    addedLayers: [],
    layerIsLoading: false,
    secondaryViewerLayerIsLoading: false,
    geometryValidationSteps: null,
    globalTemplates: [],
    userSelectedLayers: null,
    dataCriteriaGraphDefinitions: []
  };
}

const state = initialState();

const getters = {
  simulations(state) {
    return state.simulations;
  },
  simulationCategory(state, getters) {
    if (getters.simulation != null) {
      return getters.simulation.category;
    }
    return null;
  },
  /* set to true when the viewer is loading a layer and back to false when complete */
  layerIsLoading(state) {
    return state.layerIsLoading;
  },
  secondaryViewerLayerIsLoading(state) {
    return state.secondaryViewerLayerIsLoading;
  },
  /* the selected simulation/configuration id.  this can be mapped/watched to find out when a user changes the selected configuration */
  selectedSimulationId(state) {
    return state.selectedSimulationId;
  },
  selectedSecondaryViewerSimulationId(state) {
    return state.selectedSecondaryViewerSimulationId;
  },
  selectedInsightConfigurationId(state) {
    return state.selectedInsightConfigurationId;
  },
  simulation(state) {
    let sim = null;
    if (state.simulations !== null && state.simulations.length > 0 && state.selectedSimulationId !== null) {
      sim = state.simulations.find(simulation => simulation.configuration.id == state.selectedSimulationId);
    }
    return sim;
  },
  secondaryViewerSimulation(state) {
    let sim = null;
    if (state.simulations !== null && state.simulations.length > 0 && state.selectedSecondaryViewerSimulationId !== null) {
      sim = state.simulations.find(simulation => simulation.configuration.id == state.selectedSecondaryViewerSimulationId);
    }
    return sim;
  },
  colorLegend(state) {
    return state.colorLegend;
  },
  dataCriteriaGraphDefinitions(state) {
    return state.dataCriteriaGraphDefinitions;
  },
  configurationId(state) {
    return state.selectedSimulationId;
  },
  /*
    The master list of assets returned from the API call (filtered to the selected simulation) with windroses, data, and geometry filtered out as this is supposed to only represent assets that are
    simulation result layers (i.e. they can be added to the viewers as result layers to view)
  */
  simulationResultAssets(state) {
    if (!state.selectedSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      return createFilteredAssets(simulationAssets, filterSimulationResultAssets);
    }
  },
  secondaryViewerSimulationResultAssets(state) {
    if (!state.selectedSecondaryViewerSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSecondaryViewerSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      return createFilteredAssets(simulationAssets, filterSimulationResultAssets);
    }
  },
  selectedInsightSimulationResultAssets(state) {
    if (!state.selectedInsightConfigurationId || !state.simulations) {
      return [];
    }

    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedInsightConfigurationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      return createFilteredAssets(simulationAssets, filterSimulationResultAssets);
    }

  },
  /*
    the geometry layers from the master list of assets returned from the API call
  */
  geometryAssets(state) {
    if (!state.selectedSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let filteredAssets = createFilteredAssets(simulationAssets, filterGeometryAssets);
      return _.cloneDeep(filteredAssets);
    }
  },
  secondaryViewerGeometryAssets(state) {
    if (!state.selectedSecondaryViewerSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSecondaryViewerSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let filteredAssets = createFilteredAssets(simulationAssets, filterGeometryAssets);
      return _.cloneDeep(filteredAssets);
    }
  },
  insightGeometryAssets(state) {
    if (!state.selectedInsightConfigurationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedInsightConfigurationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let filteredAssets = createFilteredAssets(simulationAssets, filterGeometryAssets);
      return _.cloneDeep(filteredAssets);
    }
  },

  dataAssets(state) {
    if (!state.selectedSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let filteredAssets = createFilteredAssets(simulationAssets, filterDataAssets);
      return filteredAssets;
    }
  },

  graphAssets(state) {
    if (!state.selectedSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let filteredAssets = createFilteredAssets(simulationAssets, filterGraphAssets);
      return filteredAssets;
    }
  },
  secondaryViewerGraphAssets(state) {
    if (!state.selectedSecondaryViewerSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSecondaryViewerSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let filteredAssets = createFilteredAssets(simulationAssets, filterGraphAssets);
      return _.cloneDeep(filteredAssets);
    }
  },

  /* Windrose assets for the selected configuration */
  windroses(state) {
    if (!state.selectedSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let windroses = simulationAssets.filter(asset => asset.layer_type?.toLowerCase() === 'windrose' && asset.filename.includes('.svg'));
      for (let i=0; i<windroses.length; i++) {
        windroses[i] = PropertySetter.append_calculated_properties(windroses[i]);
      }
      return windroses;
    }
  },
  secondaryViewerWindroses(state) {
    if (!state.selectedSecondaryViewerSimulationId || !state.simulations) {
      return [];
    }
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSecondaryViewerSimulationId);
    if (!simulation) {
      return [];
    } else {
      const simulationAssets = simulation.assets ?? [];
      let windroses = simulationAssets.filter(asset => asset.layer_type?.toLowerCase() === 'windrose' && asset.filename.includes('.svg'));
      for (let i=0; i<windroses.length; i++) {
        windroses[i] = PropertySetter.append_calculated_properties(windroses[i]);
      }
      return windroses;
    }
  },

  /*
    All filter values that have been applied by the user to filter down the simulationResultAssets list into the result set that they want to display.  The global
    filter structure is build dynamically based on the properties of the assets loaded (some properties are excluded - the list of excluded properties is defined in
    models/asset.ts).  A typical global filter would look something like this:

    {
      "analysis_type": ["Solar", "ThermalComfort"],
      "calucation": [],
      "season": [],
      "wind_direction": []
    }

    where the keys are built dynamically from the content of the assets loaded and the values are populated by the user
  */
  globalFilter(state) {
    return state.globalFilter;
  },
  secondaryViewerGlobalFilter(state) {
    return state.secondaryViewerGlobalFilter;
  },
  selectedInsightGlobalFilter(state) {
    return state.selectedInsightGlobalFilter;
  },
  /* The global filter is applied to the master assets list (simulationResultAssets) to get the list of assets that match the filter */
  assetsMatchingGlobalFilter(state, getters) {
    return getters.simulationResultAssets.filter(asset => asset.matchesFilter(state.globalFilter));
  },

  secondaryViewerAssetsMatchingGlobalFilter(state, getters) {
    return getters.secondaryViewerSimulationResultAssets.filter(asset => asset.matchesFilter(state.secondaryViewerGlobalFilter));
  },
  

  /*
    The keys added to the global filter (based on the properties of the loaded assets) are then used to dynamically build the layer-picker UI.  This collection
    is iterated over to build the list of selectable filters in the layer panel
  */
  layerPickerInputs(state, getters) {
    return Object
      .keys(state.globalFilter)
      .sort((a, b) =>  AssetSorting.GetSortOrder(a) - AssetSorting.GetSortOrder(b))  //sorting is according to placement in the asset-constants.KEYS_TO_FILTER_BY array
      .map(key => new Input(key, getters.simulationResultAssets, state.globalFilter));
  },
  secondaryViewerLayerPickerInputs(state, getters) {
    return Object
      .keys(state.secondaryViewerGlobalFilter)
      .sort((a, b) =>  AssetSorting.GetSortOrder(a) - AssetSorting.GetSortOrder(b))  //sorting is according to placement in the asset-constants.KEYS_TO_FILTER_BY array
      .map(key => new Input(key, getters.secondaryViewerSimulationResultAssets, state.secondaryViewerGlobalFilter));
  },

  /*
  The list of matching assets is then grouped into layer sets.  The group-by key is the "Fully Qualified Filter" value, which is basically a set
  of filter values that describes exactly 1 layer set.

  e.g.
    {
      "analysis_type": "Directional Wind",
      "calucation": "Mean Wind Speed",
      "season": "Dry",
      "wind_direction": "045.0"
    }

  Note that sub-surfaces are not included in the filter so when we apply a group-by using the filter as a key we're left with 1 group (a layer set) with x
  assets (where x is equal to the number of sub-surfaces)
  */
  layerSetsMatchingGlobalFilter(state, getters) {
    if (_.isEmpty(state.globalFilter)) {
      return [];
    }

    return Object
      .entries(_.groupBy(getters.assetsMatchingGlobalFilter, asset => grouper(asset, state.globalFilter)))
      .map(([key, assets]) => new LayerSet(key, assets));
  },

  secondaryViewerLayerSetsMatchingGlobalFilter(state, getters) {
    if(_.isEmpty(state.secondaryViewerGlobalFilter)) {
      return [];
    }

    return Object
      .entries(_.groupBy(getters.secondaryViewerAssetsMatchingGlobalFilter, asset => grouper(asset, state.secondaryViewerGlobalFilter)))
      .map(([key, assets]) => new LayerSet(key, assets));
  },

  /*
  The user is able to select entries from the "matching layers" table to add to the layer panel.  The table contains the "Fully Qualified Filter" of each layer set
  and the selected entries are stored as stringified versions of the filter.  To get the list of actual layerSets that the user has selected we need to compare the selected
  "fully qualified filters" to the "layerSets that match the global filter"
  */
  selectedLayerSets(state, getters) {
    if (_.isEmpty(state.globalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return getters.allLayerSets.filter(layerSet =>
      state.selectedLayers.includes(layerSet.identifier)
    );
  },
  secondaryViewerSelectedLayerSets(state, getters) {
    if (_.isEmpty(state.secondaryViewerGlobalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return getters.allSecondaryViewerLayerSets.filter(layerSet =>
      state.selectedLayers.includes(layerSet.identifier)
    );
  },

  /*
  this keeps track of the layer sets that have been added to the layers panel so that both the layers panel and the layer-picker know what's been previously added.
  */
  addedLayerSets(state) {
    return state.addedLayers;
  },

  /* The geometry assets grouped into layer sets */
  geometryLayerSets(state, getters) {

    function getGeometryIdentifier(asset) {
      let geometryType = asset.simulationResult.geometry_type;
      let generation = asset.simulationResult.geometry_generation;

      if (generation) {
        return `${geometryType} - ${generation}`;
      } else {
        return `${geometryType}`;
      }
    }
    return _.cloneDeep(getters.geometryAssets).map(asset => new LayerSet(getGeometryIdentifier(asset), [asset]));
  },
  secondaryViewerGeometryLayerSets(state, getters) {

    function getGeometryIdentifier(asset) {
      let geometryType = asset.simulationResult.geometry_type;
      let generation = asset.simulationResult.geometry_generation;

      if (generation) {
        return `${geometryType} - ${generation}`;
      } else {
        return `${geometryType}`;
      }
    }
    return _.cloneDeep(getters.secondaryViewerGeometryAssets).map(asset => new LayerSet(getGeometryIdentifier(asset), [asset]));
  },
  insightGeometryLayerSets(state, getters) {

    function getGeometryIdentifier(asset) {
      let geometryType = asset.simulationResult.geometry_type;
      let generation = asset.simulationResult.geometry_generation;

      if (generation) {
        return `${geometryType} - ${generation}`;
      } else {
        return `${geometryType}`;
      }
    }
    return _.cloneDeep(getters.insightGeometryAssets).map(asset => new LayerSet(getGeometryIdentifier(asset), [asset])).filter(layer => layer.identifier !== 'presentation plane');
  },

  /* Full list of assets loaded grouped into layer sets.  */
  allLayerSets(state, getters) {
    if (_.isEmpty(state.globalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return Object
      .entries(_.groupBy(getters.simulationResultAssets, asset => grouper(asset, state.globalFilter)))
      .map(([key, assets]) => new LayerSet(key, assets));
  },
  allSecondaryViewerLayerSets(state, getters) {
    if (_.isEmpty(state.secondaryViewerGlobalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return Object
      .entries(_.groupBy(getters.secondaryViewerSimulationResultAssets, asset => grouper(asset, state.secondaryViewerGlobalFilter)))
      .map(([key, assets]) => new LayerSet(key, assets));
  },

  //similar to the allLayerSets except it uses the simulation record associated with the selected insight's config/sim record, which may differ
  //from the selected config on the layerpanel (viewer mode)
  allLayersetsForSelectedInsightSimulation(state, getters) {
    if (_.isEmpty(state.selectedInsightGlobalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return Object
      .entries(_.groupBy(getters.selectedInsightSimulationResultAssets, asset => grouper(asset, state.selectedInsightGlobalFilter)))
      .map(([key, assets]) => new LayerSet(key, assets));
  },

  /* layer sets that have at least 1 asset that's marked as "show_by_default" */
  showByDefaultLayerSets(state, getters) {
    if (_.isEmpty(state.globalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return getters.allLayerSets.filter(layerSet => layerSet.showByDefault === true);
  },
  secondaryViewerShowByDefaultLayerSets(state, getters) {
    if (_.isEmpty(state.secondaryViewerGlobalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return getters.allSecondaryViewerLayerSets.filter(layerSet => layerSet.showByDefault === true);
  },

  /* geometry layer sets that have at least 1 asset that's marked as "show_by_default" */
  showByDefaultGeometryLayerSets(state, getters) {
    if (_.isEmpty(state.globalFilter)) {  //without a global filter all assets will get grouped into 1 layerSet, which is not what we want!
      return [];
    }

    return getters.geometryLayerSets.filter(layerSet => layerSet.showByDefault === true);
  },

  /* Generally we want things grouped into assets but this is necessary to de-couple the assets actually being updated from the global filter being updated.  Since the
  global filter is used to group assets into layer sets, the various layer-set getters are all triggered when the global filter changes.  We need to know when the underlying
  "default assets" changes so instead of watching the default layer sets we can watch the default assets, then use them grouped into layersets if need be. */
  defaultAssets(state, getters) {
    return getters.simulationResultAssets.filter(asset => asset.simulationResult.show_by_default === true);
  },

  secondaryViewerDefaultAssets(state, getters) {
    return getters.secondaryViewerSimulationResultAssets.filter(asset => asset.simulationResult.show_by_default === true);
  },

  /*
    There may be a difference between the filters that have been applied to the global filter and the filters that are actually being actively used to filter data.  For
    instance, the user may add the following filters to the global filter:

    analysis_type: ["Solar", "Thermal Comfort"],
    calculation: ["% Sun Lit"]

    in this case "Thermal Comfort" isn't contributing to the result set since the "% Sun Lit" filter removes anything related to Thermal Comfort.  In this case, the global \
    filter still contains all of the filter values added (as depicted in the example above) but the applicableFilter getter below would contain only the filter values that
    are actively contributing to the current result set.

    e.g.
    analysis_type: ["Solar"],
    calculation: ["% Sun Lit"]
  */
  applicableFilter(state, getters) {
    let filter = copyGlobalFilterKeys(state.globalFilter);
    Object.entries(state.globalFilter).forEach(([key, values]) => {
      values.forEach(value => {
        const oneFilteredAssetHasValue = getters.assetsMatchingGlobalFilter.map(asset => asset.simulationResult[key]).includes(value);
        const applicableFilterDoesNotContainValue = !filter[key].includes(value);
        if (oneFilteredAssetHasValue && applicableFilterDoesNotContainValue) {
          filter[key].push(value);
        }
      });
    });
    return filter;
  },
  secondaryViewerApplicableFilter(state, getters) {
    let filter = copyGlobalFilterKeys(state.secondaryViewerGlobalFilter);
    Object.entries(state.secondaryViewerGlobalFilter).forEach(([key, values]) => {
      values.forEach(value => {
        const oneFilteredAssetHasValue = getters.secondaryViewerAssetsMatchingGlobalFilter.map(asset => asset.simulationResult[key]).includes(value);
        const applicableFilterDoesNotContainValue = !filter[key].includes(value);
        if (oneFilteredAssetHasValue && applicableFilterDoesNotContainValue) {
          filter[key].push(value);
        }
      });
    });
    return filter;
  },

  /* The applicationFilter is then used by the appliedFilterList getter to show badges above the "selected layersets" table in the layer picker modal window */
  appliedFilterList(state, getters) {
    let resultSet = [];
    Object.entries(getters.applicableFilter).forEach(([key, values]) => {
      values.forEach(value => {
        var filter = { key: key, value: value };
        resultSet.push(filter);
      });
    });
    return resultSet;
  },
  secondaryViewerAppliedFilterList(state, getters) {
    let resultSet = [];
    Object.entries(getters.secondaryViewerApplicableFilter).forEach(([key, values]) => {
      values.forEach(value => {
        var filter = { key: key, value: value };
        resultSet.push(filter);
      });
    });
    return resultSet;
  },
  simulationIsLoading(state) {
    return state.simulations == null || state.userSelectedLayers == null;
  },
  /* a dict of all layer types and their associated presentation planes (from the configuration's asset list) */
  subSurfacesForAddedLayers(state) {
    let dict = {};
    let layerTypes = state.addedLayers.map(layer => layer.layerType)
      .filter((value, index, self) => {  //get the unique set from the list
        return self.indexOf(value) === index;
      })
      .filter(value => value);  //get rid of any empty strings

    layerTypes.forEach(layerType => {
      var presentationPlanesForLayerType = state.addedLayers.filter(layer => layer.layerType == layerType)
        .flatMap(layer => layer.layerSetAssets)  //map to a flat list of assets inside the selectedLayerSets collection
        .map(asset => asset.simulationResult.subsurface_type) //create a list of subsurfaces in all of the assets
        .filter((value, index, self) => {  //get the unique set from the list
          return self.indexOf(value) === index;
        })
        .filter(value => value);   //get rid of any empty strings

      dict[layerType] = presentationPlanesForLayerType;
    });

    return dict;
  },
  /* JSON representation of any CFD parameters that have previously been entered and saved to the DB.  Used to reload progress of an INBOUND simulation */
  submittedCFDParameterFormValues(state, getters) {
    if (getters.simulation) {
      return getters.simulation.cfd_parameters;
    } else {
      return null;
    }
  },
  remeshedPresentationPlaneExists(state, getters) {
    if (getters.geometryAssets) {
      for (let asset of getters.geometryAssets) {
        if (asset.simulationResult.geometry_type === 'presentation plane' && asset.simulationResult.remeshed_assets.length > 0) {
          return true;
        }
      }
    }
    return false;
  },
  geometryValidationSteps(state) {
    return state.geometryValidationSteps;
  },
  globalTemplates(state) {
    return state.globalTemplates;
  },
  userSelectedLayers(state) {
    return state.userSelectedLayers;
  },
  simulationsFinishedLoading(state) {
    return state.simulationsFinishedLoading;
  },
  firstSimulationFinishedLoading(state) {
    return state.firstSimulationFinishedLoading;
  }
};

const actions = {

  setLayerIsLoading({ commit }, isLoading) {
    commit('setLayerIsLoading', isLoading);
  },
  setSecondaryViewerLayerIsLoading({ commit }, isLoading) {
    commit('setSecondaryViewerLayerIsLoading', isLoading);
  },
  resetState({ commit }) {
    commit('resetState');
  },
  async getSimulations({ commit, state, getters }, parameters) {
    try {

      //if the user navigates away from the ViewerContainer just before this is triggered the route updates and these variables are undefined, causing errors.
      //if the user has done this there's no point in making these API calls
      if (parameters.projectId && parameters.studyId && state.selectedSimulationId) {
        // load simulations for config because it's faster
        let task1, task2, task3;
        let sim_id = state.selectedSimulationId;  //selectedSimulationId can change between line 533 and 539 due to a redirect happening during the async call and we're relying on it staying constant
        let second_sim_id = state.selectedSecondaryViewerSimulationId;
        task1 = projectApiService.getSimulationsForConfiguration(parameters.projectId, parameters.studyId, sim_id);
        if(second_sim_id) {
          task2 = projectApiService.getSimulationsForConfiguration(parameters.projectId, parameters.studyId, second_sim_id);
        }
        task3 = projectApiService.getSimulations(parameters.projectId, parameters.studyId);
        let response = await task1;
        const simulation = response.find(simulation => simulation.configuration.id === sim_id);
        if (!simulation) {
          commit('setError', `unable to find simulation for selected simulationId ${sim_id}`);
          return [];
        }
        const simulationAssets = simulation.assets ?? []; // May want to consider just using this to set in the store
        commit('setGlobalFilter', createFilteredAssets(simulationAssets, filterSimulationResultAssets));
        await commit('setSimulations', response);
        commit('firstSimulationFinishedLoading', response[0].configuration.id);

        //show_by_default layers need to be loaded into the 'addedLayers' state for the toggling functionality on the layers panel to work correctly with them.
        await commit('setAddedLayerSets', {
          addedLayers: getters.showByDefaultLayerSets,
          simulationId: state.selectedSimulationId,
          group: null,
          addedByUser: false});

        const userSelectedLayers = new UserSelectedLayers(parameters.projectId, parameters.studyId);
        await userSelectedLayers.load();
        await commit('setUserSelectedLayers', userSelectedLayers);

        if (state.selectedSecondaryViewerSimulationId) {
          let response = await task2;
          const secondaryViewerSimulation = response.find(simulation => simulation.configuration.id === second_sim_id);
          if (!secondaryViewerSimulation) {
            commit('setError', `unable to find simulation for selected simulationId ${second_sim_id}`);
            return [];
          }
          const secondaryViewerSimulationAssets = secondaryViewerSimulation.assets ?? [];
          commit('setSecondaryViewerGlobalFilter', createFilteredAssets(secondaryViewerSimulationAssets, filterSimulationResultAssets));
          await commit('appendSimulations', {response, second_sim_id});
        }

        // load the rest of the simulations
        response = await task3;
        await commit('appendSimulations', {response, sim_id});  //since state.selectedSimuationId could have changed we need to let the appendSimulations method know which id is already in the state.simulations list
        commit('setSimulationsFinishedLoading', true);
      }
    } catch (error) {
      commit('setError', error);
    }
  },
  selectSimulation({ commit, getters }, simulationId) {
    commit('clearAddedLayerSets');
    commit('setSelectedSimulationId', simulationId);

    //the global filter needs to be rebuilt after changing simulations as the different filter options may differ in the result set for the selected silumation.
    //additionally, the state gets reset when you change projects and if you have no global filter then all assets group into the same layerSet and the viewer tries to load all simulation assets at once
    commit('setGlobalFilter', getters.simulationResultAssets);
  },
  selectSecondaryViewerSimulation({ commit, getters }, simulationId) {
    commit('clearAddedLayerSets');
    commit('setSelectedSecondaryViewerSimulationId', simulationId);

    //the global filter needs to be rebuilt after changing simulations as the different filter options may differ in the result set for the selected silumation.
    //additionally, the state gets reset when you change projects and if you have no global filter then all assets group into the same layerSet and the viewer tries to load all simulation assets at once
    commit('setSecondaryViewerGlobalFilter', getters.secondaryViewerSimulationResultAssets);
  },
  /**
   *
   * @param {object} param0: Object containing commit and getters
   * @param {object} data: Object from vue-formulate form. Preferably in the format { [key: string]: string[] } but this action will filter out values that aren't lists.
   *
   * Description of method:
   * For each input in the form we need to update the global filter
   * `data` is an object with a list of values the user has checked. {'analysis_type': ['Solar'], 'season': ['summer']}
   * 1. entries:
   * 2. filter: we filter out key/value pairs if the global filter already matches the checked entries
   * 3. filter: we filter out empty strings (issue with how vue-formulate initializes formValues)
   * 4. forEach: add the filter value to the globalFilter in the store
   */
  setFilterValue({ commit, getters }, data) {
    Object
      .entries(data)
      .filter(([key, value]) => getters.globalFilter[key].length !== value.length || getters.globalFilter[key].some(element => !value.includes(element)))
      .filter(([, value]) => Array.isArray(value))
      .forEach(([key, value]) => commit('setFilterValue', { key, value }));
  },
  setSecondaryViewerFilterValue({ commit, getters }, data) {
    Object
      .entries(data)
      .filter(([key, value]) => getters.secondaryViewerGlobalFilter[key].length !== value.length || getters.secondaryViewerGlobalFilter[key].some(element => !value.includes(element)))
      .filter(([, value]) => Array.isArray(value))
      .forEach(([key, value]) => commit('setSecondaryViewerFilterValue', { key, value }));
  },
  clearGlobalFilter({ commit }, filterName) {
    commit('clearGlobalFilter', filterName);
  },
  clearSecondaryViewerGlobalFilter({ commit }, filterName) {
    commit('clearSecondaryViewerGlobalFilter', filterName);
  },
  setSelectedLayerSets({ commit }, data) {
    commit('setSelectedLayerSets', data.map(item => JSON.stringify(item)));  //JSON.stringify converts it to a string that matchines a layer set's "idenifier");
  },
  clearSelectedLayerSets({commit}) {
    commit('clearSelectedLayerSets');
  },
  removeAddedLayerSet({ commit }, { simulationId, layerToDelete, viewId }) {
    commit('removeAddedLayerSet', { simulationId, layerToDelete, viewId });
  },
  setAddedLayerSets({ commit},  { addedLayers, simulationId, viewId, addedByUser }) {
    commit('setAddedLayerSets',  { addedLayers, simulationId, viewId, addedByUser });
  },
  clearAddedLayerSets({ commit }) {
    commit('clearAddedLayerSets');
  },
  async getSimulationAsset({ commit, getters }, parameters) {
    try {
      const response = await projectApiService.getSimulationAsset(
        parameters.projectId,
        parameters.studyId,
        getters.configurationId,
        getters.simulation.id,
        parameters.assetId
      );
      await commit('setSimulationAsset', response);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async getColorLegendByName({ commit }, {colorLegendName, colorLegendVersion}) {
    try {
      const response = await projectApiService.getColorLegendByName(colorLegendName,colorLegendVersion);
      await commit('setCurrentColorLegendKey', {colorLegendName, colorLegendVersion});
      await commit('setColorLegend', response);
      return response;
    } catch (error) {
      commit('setError', error);
    }
  },
  async getDataCriteriaGraphDefinitions({ commit }) {
    try {
      const response = await projectApiService.getDataCriteriaGraphDefinitions();
      commit('setDataCriteriaGraphDefinitions', response);
    } catch(error) {
      commit('setError', error);
    }
  },
  async remeshStl({ commit }, { projectId, studyId, configurationId, simulationId, simulationAssetId }) {
    try {
      await projectApiService.remeshStl(projectId, studyId, configurationId, simulationId, simulationAssetId);
    } catch (error) {
      commit('setError', error);
    }
  },
  addSimulationAsset({commit}, { configurationId, asset}) {
    commit('addSimulationAsset', {configurationId, asset});
  },
  removeSimulationAsset({commit}, { configurationId, assetId}) {
    commit('removeSimulationAsset', {configurationId, assetId});
  },
  async createGeometryValidationSteps({ commit }, { projectId, studyId, configurationId, geometryValidationSteps }) {
    try {
      const response = await projectApiService.createGeometryValidationSteps(projectId, studyId, configurationId, geometryValidationSteps);
      commit('setGeometryValidationSteps', response);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async updateGeometryValidationSteps({ commit }, { projectId, studyId, configurationId, geometryValidationStepsId, geometryValidationSteps }) {
    try {
      await projectApiService.replaceGeometryValidationSteps(projectId, studyId, configurationId, geometryValidationStepsId, geometryValidationSteps);
      commit('setGeometryValidationSteps', geometryValidationSteps);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },
  async getGeometryValidationSteps({ commit }, { projectId, studyId, configurationId }) {
    try {
      const response = await projectApiService.getGeometryValidationSteps(projectId, studyId, configurationId);
      commit('setGeometryValidationSteps', response[0]);
    } catch (error) {
      commit('setError', error);
      throw error;
    }
  },

  async getGlobalTemplates({ commit }) {
    try {
      const globalTemplates = await projectApiService.getGlobalTemplates();
      commit('getGlobalTemplates', globalTemplates);
    } catch(error) {
      console.error(error);
    }
  },

  setSelectedMetId({ commit }, selectedMetId) {
    commit('setSelectedMetId', selectedMetId);
  },
  
  setSelectedInsightConfigurationId({ commit, getters }, insightConfigurationId) {
    commit('setSelectedInsightConfigurationId', insightConfigurationId);
    commit('setSelectedInsightGlobalFilter', getters.selectedInsightSimulationResultAssets);
  }
};

const mutations = {
  setSelectedInsightConfigurationId(state, insightConfigurationId) {
    state.selectedInsightConfigurationId = insightConfigurationId;
  },
  setLayerIsLoading(state, isLoading) {
    state.layerIsLoading = isLoading;
  },
  setSecondaryViewerLayerIsLoading(state, isLoading) {
    state.secondaryViewerLayerIsLoading = isLoading;
  },
  resetState(state) {
    Object.entries(initialState()).forEach(element => state[element[0]] = element[1]);
  },
  setSimulations(state, data) {
    state.simulations = data;
  },
  appendSimulations(state, data) {
    let api_resp = data.response;
    let sim_id = data.sim_id;
    //1 simulation has already been loaded and the rest are now complete and need to be appended.  Do not overwrite the current simulaiton because the user may have added files to it between when it loaded and when the full set loaded
    let all_but_current = api_resp.filter(simulation => simulation.configuration.id != sim_id);
    state.simulations = state.simulations.concat(all_but_current);
  },
  setSelectedSimulationId(state, data) {
    state.selectedSimulationId = data;
  },
  setSelectedSecondaryViewerSimulationId(state, data) {
    state.selectedSecondaryViewerSimulationId = data;
  },
  setGlobalFilter(state, assets) {
    state.globalFilter = {};
    assets.forEach(asset => {
      asset.propertyNames.forEach(name => {
        state.globalFilter[name] = [];
      });
    });
  },
  setSecondaryViewerGlobalFilter(state, assets) {
    state.secondaryViewerGlobalFilter = {};
    assets.forEach(asset => {
      asset.propertyNames.forEach(name => {
        state.secondaryViewerGlobalFilter[name] = [];
      });
    });
  },
  setSelectedInsightGlobalFilter(state, assets) {
    state.selectedInsightGlobalFilter = {};
    assets.forEach(asset => {
      asset.propertyNames.forEach(name => {
        state.selectedInsightGlobalFilter[name] = [];
      });
    });
  },
  setFilterValue(state, filterParameter) {
    state.globalFilter = {
      ...state.globalFilter,
      [filterParameter.key]: filterParameter.value,
    };
  },
  setSecondaryViewerFilterValue(state, filterParameter) {
    state.secondaryViewerGlobalFilter = {
      ...state.secondaryViewerGlobalFilter,
      [filterParameter.key]: filterParameter.value,
    };
  },
  clearGlobalFilter(state, filterName) {
    state.globalFilter = {
      ...state.globalFilter,
      [filterName]: []
    };
  },
  clearSecondaryViewerGlobalFilter(state, filterName) {
    state.secondaryViewerGlobalFilter = {
      ...state.secondaryViewerGlobalFilter,
      [filterName]: []
    };
  },
  setSelectedLayerSets(state, selectedItems) {
    state.selectedLayers = selectedItems;
  },
  clearSelectedLayerSets(state) {
    state.selectedLayers = [];
  },
  async removeAddedLayerSet(state, {simulationId, layerToDelete, viewId}) {
    const index = state.addedLayers.findIndex(layer => layer === layerToDelete.identifier);
    if (index > -1) {
      state.addedLayers.splice(index, 1);
    }
    await state.userSelectedLayers.remove(simulationId, layerToDelete, viewId);
  },
  async setAddedLayerSets(state,  { addedLayers, simulationId, viewId, addedByUser }) {
    state.addedLayers = addedLayers;
    if (addedByUser === true) { 
      if (viewId === null || viewId === 'undefined') {
        throw 'When addedByUser is True, viewId value is required';
      }
      await state.userSelectedLayers.replace(simulationId, state.addedLayers, viewId);
    }
  },
  clearAddedLayerSets(state) {
    state.addedLayers = [];
  },
  setError(state, error) {
    state.error = error;
  },
  setColorLegend(state, data) {
    state.colorLegend[`${state.currentColorLegendKey}`] = data;
  },
  setDataCriteriaGraphDefinitions(state, data) {
    state.dataCriteriaGraphDefinitions = data;
  },
  setCurrentColorLegendKey(state, {colorLegendName, colorLegendVersion}) {
    state.currentColorLegendKey = `"${colorLegendName}-${colorLegendVersion}"`;
  },
  setSimulationAsset(state, data) {
    const simulation = state.simulations.find(simulation => simulation.configuration.id === state.selectedSimulationId);
    if (simulation) {
      var matchingAsset = simulation.assets.find(asset => asset.id == data.id);
      if (matchingAsset) {
        matchingAsset = data;
      }
    }
  },
  addSimulationAsset(state, {configurationId, asset}) {
    let matchingSimulation = state.simulations.find(simulation => simulation.configuration.id == configurationId);
    matchingSimulation.assets.push(asset.simulationResult);
  },
  removeSimulationAsset(state, {configurationId, assetId}) {
    let matchingSimulation = state.simulations.find(simulation => simulation.configuration.id == configurationId);
    let matchingAssetIndex  = matchingSimulation.assets.findIndex(asset => asset.id == assetId);
    if (matchingAssetIndex > -1) {
      matchingSimulation.assets.splice(matchingAssetIndex, 1);
    }
  },
  setGeometryValidationSteps(state, data) {
    state.geometryValidationSteps = data;
  },
  getGlobalTemplates(state, globalTemplates) {
    state.globalTemplates = globalTemplates;
  },
  setUserSelectedLayers(state, userSelectedLayers) {
    state.userSelectedLayers = userSelectedLayers;
  },
  setSelectedMetId(state, selectedMetId) {
    let simulation = state.simulations.find(simulation => simulation.configuration.id == state.selectedSimulationId);
    simulation.met_source_id = selectedMetId;
  },
  setSimulationsFinishedLoading(state, simulationsFinishedLoading) {
    state.simulationsFinishedLoading = simulationsFinishedLoading;
  },
  firstSimulationFinishedLoading(state, config_id) {
    state.firstSimulationFinishedLoading = config_id;
  }
};

// ------ Move these to a utility ts file ------
function createFilteredAssets(assets, filterFunction) {
  return assets
    .filter(asset => asset.layer_type !== undefined)
    .filter(filterFunction)
    .map(asset => new Asset(asset));
}

function copyFilterKey(accumulatedFilter, key) {
  accumulatedFilter[key] = [];
  return accumulatedFilter;
}

function copyGlobalFilterKeys(globalFilter) {
  let filter = {};
  Object
    .keys(globalFilter)
    .reduce(copyFilterKey, filter);
  return filter;
}

function filterSimulationResultAssets(asset) {
  return asset.layer_type.toLowerCase() !== 'windrose' 
    && asset.layer_type.toLowerCase() !== 'geometry' 
    && asset.layer_type.toLowerCase() !== 'data'
    && asset.layer_type.toLowerCase() !== 'graph';
}

function filterGeometryAssets(asset) {
  return asset.layer_type?.toLowerCase() === 'geometry';
}

function filterDataAssets(asset) {
  return asset.layer_type?.toLowerCase() === 'data';
}

function filterGraphAssets(asset) {
  return asset.layer_type?.toLowerCase() === 'graph';
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};